import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { MyPagesStore } from 'stores'
import { User, Quote } from '~libs/api/models'
import { Icons } from '~libs/assets'
import { tx } from '~libs/i18n'
import { MoneyFormatter, PidnFormatter } from '~libs/formatters'
import { Button, Card, Input, Row, Col, AnimatedHeight, Checkbox } from '~components'
import { IInputItem } from '~stores/ApplicationUIStore/props'
import styles from './styles.module.scss'

export interface QuoteCardProps {
  user: User
  quote: Quote
  myPagesStore?: MyPagesStore
}

@inject('myPagesStore')
@observer
export class QuoteCard extends Component<QuoteCardProps> {
  state = {
    currentStep: 0,
    acceptedSteps: {},
  }

  mounted: boolean = false

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onBlur = item => {
    const { myPagesStore } = this.props
    myPagesStore.validateItem(item, true)
  }

  onEnter = item => {
    const { myPagesStore } = this.props
    myPagesStore.validateItem(item, true)
  }

  onChange = (value, item, acceptedKey) => {
    item.value = value
    const { acceptedSteps } = this.state
    this.setState({ acceptedSteps: { ...acceptedSteps, [acceptedKey]: false } })
  }

  allStepsAccepted = () => {
    const { acceptedSteps } = this.state
    return undefined === Object.keys(acceptedSteps).find(key => !acceptedSteps[key])
  }

  incrementStep = index => {
    if (this.mounted) {
      const { currentStep, acceptedSteps } = this.state
      this.setState({
        currentStep: Math.max(currentStep, index + 1),
        acceptedSteps: { ...acceptedSteps, [index]: true },
      })
    }
  }

  onAcceptStep = (item, index) => {
    const { myPagesStore } = this.props
    if (myPagesStore.validateItems(item.validateItems, true)) {
      this.incrementStep(index)
    }
  }

  onAcceptAutogiro = (_, index) => {
    const { myPagesStore, quote } = this.props
    myPagesStore.updateQuoteAutogiro(quote, () => this.incrementStep(index))
  }

  onAcceptContact = (_, index) => {
    const { myPagesStore, quote } = this.props
    myPagesStore.updateQuoteContact(quote, () => this.incrementStep(index))
  }

  addToArrayButton = (key: string) => {
    const { myPagesStore, quote } = this.props

    const txtKey = key.includes('email') ? 'add_email' : 'add_mobile'
    return (
      <button
        key={`add_${key}`}
        className={styles.add_button}
        onClick={() => myPagesStore.addToQuoteArray(quote, key)}
      >
        <img src={Icons.plus} />
        {tx(`my_pages.accept_quote.${txtKey}`)}
      </button>
    )
  }

  renderInputs = (
    items: (IInputItem | IInputItem[])[],
    itemKeys: string[],
    acceptedKey: number,
  ) => {
    return items
      .map((item: any, index) => {
        if (typeof item.slice !== 'undefined') {
          // item is array
          const inputs = this.renderInputs(item, itemKeys, acceptedKey)
          inputs.push(this.addToArrayButton(itemKeys[index]))
          return inputs
        }

        return (
          <Input
            key={`${item.label}_${index}`}
            {...item}
            validator={undefined}
            onChange={value => this.onChange(value, item, acceptedKey)}
            onBlur={() => this.onBlur(item)}
            onEnter={() => this.onEnter(item)}
          />
        )
      })
      .flat()
  }

  renderInfo = () => {
    const { user, quote } = this.props
    const rows = [
      {
        title: tx('misc.name'),
        value: user.name,
      },
      {
        title: tx('misc.pidn'),
        value: PidnFormatter(user.pidn),
      },
      {
        title: tx('misc.loan_amount'),
        value: MoneyFormatter(quote.mortgage_amount),
      },
      {
        title: tx('misc.interest'),
        value: `${quote.interest_in_percent}%`,
      },
      {
        title: tx('misc.term'),
        value: tx('misc.term_in_month', { count: quote.term_in_month }),
      },
    ]

    return (
      <>
        <h3>{tx('my_pages.quote.title')}</h3>
        {rows.map(r => (
          <Row key={r.title}>
            <Col xs={6} md={4}>
              <p className={styles.title}>{r.title}</p>
            </Col>
            <Col xs={6} md={8}>
              <p className={styles.value}>{r.value}</p>
            </Col>
          </Row>
        ))}

        {quote.documents.map(document => (
          <a
            key={document.id}
            className={styles.link}
            href={`${process.env.GATSBY_HEMMA_API_URL}/document/download/${document.id}`}
            target="_blank"
            download
          >
            {document.name}
          </a>
        ))}
      </>
    )
  }

  renderSteps = () => {
    const { quote, myPagesStore } = this.props
    const { acceptedSteps, currentStep } = this.state
    const acceptQuote = myPagesStore.acceptQuotes[quote.id]

    const items = [
      {
        title: tx('my_pages.accept_quote.direct_debit_title'),
        keys: ['accountNumber'],
        onAccept: this.onAcceptAutogiro,
      },
      {
        title: tx('my_pages.accept_quote.contact_title'),
        keys: ['email', 'mobile'],
        onAccept: this.onAcceptContact,
      },
      {
        title: tx('my_pages.accept_quote.final_title'),
        content: <p>{tx('my_pages.accept_quote.final_description')}</p>,
        onAccept: this.onAcceptStep,
      },
    ]

    return items.map((item, index) => {
      const inputItems = item.keys ? item.keys.map(key => acceptQuote[key]) : []
      return (
        <AnimatedHeight
          key={item.title}
          visible={currentStep >= index}
          className={styles.step_wrapper}
        >
          <h3>
            {item.title}
            {currentStep > index && (
              <img
                className={[styles.check, !!acceptedSteps[index] ? styles.visible : null]
                  .join(' ')
                  .trim()}
                src={Icons.check_circle_green}
              />
            )}
          </h3>
          {item.content ? item.content : this.renderInputs(inputItems, item.keys, index)}
          <Button
            disabled={
              !!acceptedSteps[index] ||
              !myPagesStore.validateItems(inputItems.flat(), false)
            }
            className={styles.step_button}
            onClick={() => item.onAccept(item, index)}
          >
            {tx('misc.ok')}
          </Button>
        </AnimatedHeight>
      )
    })
  }

  render() {
    const { currentStep } = this.state
    const { quote, myPagesStore } = this.props
    const acceptQuote = myPagesStore.acceptQuotes[quote.id]

    const renderedSteps = this.renderSteps()
    const acceptAgreement = acceptQuote.acceptAgreement as IInputItem
    return (
      <Card color="white" className={styles.wrapper}>
        {this.renderInfo()}
        <form onSubmit={e => e.preventDefault()}>{renderedSteps}</form>
        <AnimatedHeight
          visible={currentStep == renderedSteps.length}
          className={styles.step_wrapper}
        >
          <h3>{tx('my_pages.accept_quote.sign_title')}</h3>
          <div className={styles.agreement_wrapper}>
            <Checkbox
              selected={acceptAgreement.value}
              onChange={value => (acceptAgreement.value = value)}
            />
            <p>{tx('my_pages.accept_quote.agreement')}</p>
          </div>
          <Button
            disabled={
              !this.allStepsAccepted() ||
              !myPagesStore.validateItem(acceptAgreement, false) ||
              ![undefined, 'idle', 'error'].includes(
                myPagesStore.signQuoteState[quote.id],
              )
            }
            className={styles.step_button}
            onClick={() => myPagesStore.signQuote(quote)}
          >
            {tx('misc.sign_bankid')}
          </Button>
        </AnimatedHeight>
      </Card>
    )
  }
}
