import React, { Component } from 'react'
import { Header } from '~views'
import { LoginSection } from '~views/shared/sections/LoginSection'
import { navigateTo } from 'gatsby'

export interface LoginPageProps {}

export class LoginPage extends Component<LoginPageProps> {
  onLogin = () => {}

  render() {
    return (
      <>
        <Header modal onClose={() => navigateTo('/')} />
        <LoginSection />
      </>
    )
  }
}
