import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import moment from 'moment'
import { List, Row, Col, Card } from '~components'
import { MyPagesStore, ListItems } from '~stores'
import { Document } from '~libs/api/models'
import styles from './styles.module.scss'
import { tx } from '~libs/i18n'

export interface DocumentListCardProps {
  myPagesStore?: MyPagesStore
}

@inject('myPagesStore')
@observer
export class DocumentListCard extends Component<DocumentListCardProps> {
  listKey = 'document'

  componentDidMount() {
    const { myPagesStore } = this.props
    const listItem = myPagesStore.listItems[this.listKey]
    if (listItem.page === 0) {
      myPagesStore.loadItems('document')
    }
  }

  renderItem = (item: Document) => {
    const date = moment(item.date).format('DD MMMM')
    const classes = [styles.item]
    if (!item.read) {
      classes.push(styles.unread)
    }
    return (
      <Row key={item.id} className={classes.join(' ')}>
        <Col xs={6} md={3}>
          {item.name}
        </Col>
        <Col xs={3} md={6} className="d-none d-md-block">
          {item.reference}
        </Col>
        <Col xs={6} md={3} className="text-right">
          {date}
        </Col>
      </Row>
    )
  }

  render() {
    const { myPagesStore } = this.props
    const listItem = myPagesStore.listItems[this.listKey] as ListItems<Document>

    return (
      <Card color="white">
        <List
          renderHeader={tx('my_pages.document_list_card.title')}
          renderEmptyView={tx('my_pages.document_list_card.empty')}
          renderItem={this.renderItem}
          items={listItem.items}
          hasMoreItems={listItem.hasMoreItems}
          loading={listItem.status === 'loading'}
          loadMoreItems={() => myPagesStore.loadItems(this.listKey)}
          badge={listItem.meta.total_read}
        />
      </Card>
    )
  }
}
