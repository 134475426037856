import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { MyPagesStore } from 'stores'
import { Button, Input, Card } from '~views/shared/components'
import { tx } from '~libs/i18n'
import styles from './styles.module.scss'
import { Icons } from '~libs/assets'

export interface InviteFriendCardProps {
  myPagesStore?: MyPagesStore
}

@inject('myPagesStore')
@observer
export class InviteFriendCard extends Component<InviteFriendCardProps> {
  state = {
    emails: [{ value: '', error: '' }],
  }

  onBlur = item => {
    const { myPagesStore } = this.props
    myPagesStore.validateItem(item, true)
  }
  onEnter = item => {
    const { myPagesStore } = this.props
    myPagesStore.validateItem(item, true)
  }

  renderInputs = () => {
    const { myPagesStore } = this.props

    return myPagesStore.inviteFriends.map((item, index) => {
      return (
        <Input
          key={`friend_${index}`}
          {...item}
          onChange={value => (item.value = value)}
          onBlur={() => this.onBlur(item)}
          onEnter={() => this.onEnter(item)}
        />
      )
    })
  }

  renderInvite = () => {
    const { myPagesStore } = this.props

    return (
      <Card color="green">
        <div className={styles.wrapper}>
          <h3>{tx('my_pages.invite_friend.title')}</h3>
          {this.renderInputs()}
          <button className={styles.add_button} onClick={myPagesStore.addInviteFriend}>
            <img src={Icons.plus} />
            {tx('my_pages.invite_friend.add_address')}
          </button>
          <Button
            disabled={!myPagesStore.isInviteFriendsValid()}
            className={styles.send_button}
            onClick={myPagesStore.sendFriendInvites}
          >
            {tx('misc.send')}
          </Button>
        </div>
      </Card>
    )
  }

  renderSuccess = () => {
    const { myPagesStore } = this.props

    return (
      <Card color="green">
        <div className={styles.wrapper_success}>
          <h2>{tx('my_pages.invite_friend.title_success')}</h2>
          <p>{tx('my_pages.invite_friend.description_success')}</p>

          <Button
            className={styles.send_button}
            onClick={myPagesStore.resetFriendInvites}
          >
            {tx('my_pages.invite_friend.button_success')}
          </Button>
        </div>
      </Card>
    )
  }

  render() {
    const { myPagesStore } = this.props

    if (myPagesStore.sendFriendInvitesState === 'success') {
      return this.renderSuccess()
    }

    return this.renderInvite()
  }
}
