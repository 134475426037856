import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Container, Button } from '~components'
import { Header, Footer } from '~views'
import { AuthStore } from '~stores'
import {
  QuoteCard,
  InviteFriendCard,
  InvoiceListCard,
  DocumentListCard,
  MortgageCard,
} from '~cards'
import styles from './styles.module.scss'
import { MyDebtSection } from '~sections'

export interface MyPagesProps {
  authStore?: AuthStore
}

@inject('authStore')
@observer
export class MyPages extends Component<MyPagesProps> {
  renderMortgages() {
    const { currentUser } = this.props.authStore
    if (!currentUser.mortgages) {
      return null
    }
    return currentUser.mortgages.map(mortgage => (
      <MortgageCard key={mortgage.id} mortgage={mortgage} />
    ))
  }

  renderQuotes() {
    const { currentUser } = this.props.authStore
    if (!currentUser.quotes) {
      return null
    }
    return currentUser.quotes.map(quote => (
      <QuoteCard key={quote.id} user={currentUser} quote={quote} />
    ))
  }

  render() {
    const { authStore } = this.props
    return (
      <>
        <Header className={styles.header} modal logout onClose={authStore.logout} />
        <section className={styles.section}>
          <Container>
            <h2>Mina sidor</h2>
            {this.renderQuotes()}
            {this.renderMortgages()}
            <InviteFriendCard />
            <InvoiceListCard />
            <DocumentListCard />
          </Container>

          <Button big outlined className={styles.logout} onClick={authStore.logout}>
            Logga ut
          </Button>
        </section>
        {authStore.currentUser.mortgages &&
          authStore.currentUser.mortgages.length >= 1 && (
            <MyDebtSection mortgage={authStore.currentUser.mortgages[0]} />
          )}
        <Footer brief />
      </>
    )
  }
}
