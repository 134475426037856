import React, { Component } from 'react'
import { Card, Col, Row } from '~components'
import { Mortgage } from '~libs/api/models'
import { tx } from '~libs/i18n'
import { MoneyFormatter } from '~libs/formatters'
import styles from './styles.module.scss'

export interface MortgageCardProps {
  mortgage: Mortgage
}

export class MortgageCard extends Component<MortgageCardProps> {
  render() {
    const { mortgage } = this.props

    return (
      <Card color="white" className={styles.wrapper}>
        <h3 className={styles.title}>{mortgage.name}</h3>
        <Row className={styles.row}>
          <Col xs={4}>
            <p>{tx('my_pages.debt.current_debt')}</p>
            <p>{tx('misc.interest')}</p>
            <p>
              {tx('my_pages.debt.due_date_at', { date: mortgage.next_invoice_due_date })}
            </p>
          </Col>
          <Col xs={4}>
            <p>{`${MoneyFormatter(mortgage.current_amount)} kr`}</p>
            <p>{`${mortgage.interest_in_percent}%`}</p>
            <p>{`${MoneyFormatter(mortgage.next_invoice_amount)} kr`}</p>
          </Col>

          <Col xs={4} className="text-right">
            {mortgage.documents.map(document => (
              <a
                key={document.id}
                href={`${process.env.GATSBY_HEMMA_API_URL}/document/download/${
                  document.id
                }`}
                download
                target="_blank"
              >
                {document.name}
              </a>
            ))}
          </Col>
        </Row>
      </Card>
    )
  }
}
