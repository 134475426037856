import React, { Component } from 'react'
import { withStore } from '~libs/mobx'
import { inject, observer } from 'mobx-react'
import { AuthStore } from '~stores'
import { MyPages, LoginPage } from '~views/pages'
import styles from './styles.module.scss'

interface AppProps {
  authStore?: AuthStore
}

@inject('authStore')
@observer
class App extends Component<AppProps> {
  render() {
    const { authStore } = this.props

    if (!authStore.loggedIn) {
      return (
        <div className={styles.wrapper}>
          <LoginPage />
        </div>
      )
    }

    return (
      <div className={styles.wrapper}>
        <MyPages />
      </div>
    )
  }
}

export default withStore(App)
