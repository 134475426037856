import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import moment from 'moment'
import { List, Row, Col, Card } from '~components'
import { MyPagesStore, ListItems } from '~stores'
import { MoneyFormatter } from '~libs/formatters'
import { Invoice } from '~libs/api/models'
import { tx } from '~libs/i18n'
import styles from './styles.module.scss'

export interface InvoiceListCardProps {
  myPagesStore?: MyPagesStore
}

@inject('myPagesStore')
@observer
export class InvoiceListCard extends Component<InvoiceListCardProps> {
  listKey = 'invoice'

  componentDidMount() {
    const { myPagesStore } = this.props
    const listItem = myPagesStore.listItems[this.listKey]
    if (listItem.page === 0) {
      myPagesStore.loadItems(this.listKey)
    }
  }

  renderItem = (item: Invoice) => {
    const period = moment()
      .set('month', item.period - 1)
      .format('MMM')

    const status = tx(`my_pages.invoice.status_${item.status}`, {
      date: item.status === 'paid' ? item.payment_date : item.due_date,
    })

    const classes = [styles.item]
    if (!item.read) {
      classes.push(styles.unread)
    }
    return (
      <Row key={item.id} className={classes.join(' ')}>
        <Col xs={3} md={2}>
          <span>{period}</span>
        </Col>
        <Col xs={4} md={3} className="d-none d-md-block text-right">
          <span>{item.reference}</span>
        </Col>
        <Col xs={4} md={3}>
          <span>{`${MoneyFormatter(item.amount)} kr`}</span>
        </Col>
        <Col xs={5} md={4} className={`text-right ${item.status}`}>
          {status}
        </Col>
      </Row>
    )
  }

  render() {
    const { myPagesStore } = this.props
    const listItem = myPagesStore.listItems[this.listKey] as ListItems<Invoice>
    return (
      <Card color="white">
        <List
          renderHeader={tx('my_pages.invoice_list_card.title')}
          renderEmptyView={tx('my_pages.invoice_list_card.empty')}
          renderItem={this.renderItem}
          items={listItem.items}
          hasMoreItems={listItem.hasMoreItems}
          loading={listItem.status === 'loading'}
          loadMoreItems={() => myPagesStore.loadItems(this.listKey)}
          badge={listItem.meta.total_read}
        />
      </Card>
    )
  }
}
